import React from "react"
import { graphql } from "gatsby"

import styled from "@emotion/styled"

import Layout from "../components/layout"
import colors from "../components/colors"
import SEO from "../components/seo"

const Wrapper = styled.div`
  padding: 0 10%;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: grey;
  background-color: ${colors.lightyellow};
  box-shadow: -20px 20px ${colors.navy};
  max-width: 100%;
  position: static;
  z-index: 2;
  padding: 1rem;
`

const Text = styled.div`
  padding-top: 0 1rem;
`

const Title = styled.h1`
`

export default function Template({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  const siteTitle = " | Groove Café"
  const name = frontmatter.title.concat(siteTitle)

  return (
    <Layout>
      <SEO  title={name} />
      <Wrapper>
        <Box>
          <Title>{frontmatter.title}</Title>
          <Text
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Box>
      </Wrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
